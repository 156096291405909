import React, { FC } from 'react';
import { Stack, useTheme } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';
import { Wrapper } from 'components/layout/header/components/navbar/common/links/addAdvertisement/common/wrapper/Wrapper';
import { AddAdvertisementIcon } from 'components/layout/header/components/navbar/common/links/addAdvertisement/common/icon/AddAdvertisementIcon';

interface IAddAdvertiser {
    href: string;
    text: string;
}

export const AddAdvertiser: FC<IAddAdvertiser> = ({ href, text }) => {
    const { palette } = useTheme();

    return (
        <>
            <Wrapper href={href}>
                <Stack
                    sx={{
                        '&:hover': {
                            bgcolor: palette.backgroundSecondary.main,
                        },
                        '&:active': {
                            bgcolor: palette.backgroundSecondary.main,
                            outline: `2px solid ${palette.backgroundPrimaryInverse.main}`,
                        },
                    }}
                    gap={0.75}
                    paddingY={0.75}
                    direction="row"
                    paddingLeft={1}
                    paddingRight={2}
                    borderRadius={10}
                    alignItems="center"
                    justifyContent="center"
                >
                    <AddAdvertisementIcon />
                    <Text variant="label1">{text}</Text>
                </Stack>
            </Wrapper>
        </>
    );
};
