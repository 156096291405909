import { FC } from 'react';
import {
    Dialog as BaseDialog,
    DialogContent,
    DialogTitle,
    Grid,
    Box,
    PaperProps,
    DialogContentProps,
} from '@mui/material';
import CloseIcon from 'modules/theme/components/icons/basic/Close.svg';
import { Text } from 'modules/theme/components/text/Text';
import { Svg } from 'modules/theme/components/svg/Svg';

interface IProps {
    isOpen: boolean;
    title?: string;
    paperProps?: Partial<PaperProps>;
    content: JSX.Element | string;
    onClose: () => void;
    dialogContentProps?: DialogContentProps;
    wrapContentIntoBox?: boolean;
    fullScreen?: boolean;
    fullWidth?: boolean;
    heightFitContent?: boolean;
    positionBottom?: boolean;
    keepMounted?: boolean;
    disablePortal?: boolean;
    closeOnBackdropClick?: boolean;
}

const Dialog: FC<IProps> = ({
    isOpen,
    title,
    content,
    onClose,
    paperProps,
    dialogContentProps,
    wrapContentIntoBox = true,
    fullScreen,
    fullWidth,
    heightFitContent,
    positionBottom,
    keepMounted = false,
    disablePortal = false,
    closeOnBackdropClick = false
}) => {
    const handleClose = (reason: string) => {
        if (reason === 'backdropClick' && !closeOnBackdropClick) {
            return;
        }

        onClose();
    };

    return (
        <BaseDialog
            open={isOpen}
            onClose={(e, reason) => handleClose(reason)}
            disableEscapeKeyDown
            maxWidth="sm"
            PaperProps={paperProps}
            fullScreen={fullScreen}
            fullWidth={fullWidth}
            keepMounted={keepMounted}
            disablePortal={disablePortal}
            sx={{
                top: positionBottom && 'auto',
                height: heightFitContent ? 'auto' : '100%',
            }}
        >
            {title && (
                <DialogTitle
                    sx={{
                        margin: 1,
                    }}
                >
                    <Grid container alignItems="center" spacing={1}>
                        <Grid item xs={12}>
                            <Svg
                                icon
                                component={CloseIcon}
                                onIconClick={onClose}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Text variant="h4" bold>
                                {title}
                            </Text>
                        </Grid>
                    </Grid>
                </DialogTitle>
            )}

            <DialogContent {...(dialogContentProps ? dialogContentProps : {})}>
                {wrapContentIntoBox ? (
                    <Box mx={1} sx={{ marginBottom: 1 }}>
                        {content}
                    </Box>
                ) : (
                    content
                )}
            </DialogContent>
        </BaseDialog>
    );
};

export default Dialog;
