export enum EGtmEvent {
    SELECT_ITEM = 'select_item',
    SELECT_PROMOTION = 'select_promotion',
    GENERATE_LEAD = 'generate_lead',
    PURCHASE = 'purchase',
    SHOW_PHONE_NUMBER = 'show_phone_number',
    SHOW_EMAIL = 'show_email',
    OPEN_WEB = 'open_web',
    CONTACT_DETAILS_MODAL = 'contact_details_modal',
    VIEW_ITEM_LIST = 'view_item_list',
    VIEW_PROMOTION = 'view_promotion',
    VIEWING = 'viewing',
    CONTACT_INFO = 'contact_info',
    VIEW_ITEM = 'view_item',
    ADD_TO_WISHLIST = 'add_to_wishlist',
    ADD_TO_CART = 'add_to_cart',
    AGENCY_INFO_CLICK = 'agency_info_click',
    AGENT_INFO_CLICK = 'agent_info_click',
    THREE_D_VIDEO = '3D_video',
    MAP = 'map',
    VIDEO = 'video',
    TRACK_EVENT = 'track-event',
    CLICK_ON_VISIBLE_PHONE_NUMBER = 'klik na už odokryté číslo',
    LOGIN = 'login',
    FILTER = 'filter',
    SUBSCRIBE_ALERT = 'subscribe_alert'
}
