import React, { FC } from 'react';
import { Svg } from 'modules/theme/components/svg/Svg';
import KeyholeInverseIcon from 'modules/theme/components/icons/basic/KeyholeOutline.svg';

export const LoginIcon: FC = () => {
    return (
        <Svg
            icon
            width={24}
            height={24}
            color="labelPrimary"
            component={KeyholeInverseIcon}
        />
    );
};
