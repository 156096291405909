import { FC } from 'react';
import { Grid } from '@mui/material';
import { SocialLoginButton } from 'components/loginModal/partials/content/partials/socialLoginButton/SocialLoginButton';
import { SocialLogin as SocialLoginConstant } from 'components/loginModal/constants/SocialLogin';
import { ESocialLogin } from 'components/loginModal/enums/ESocialLogin';
import { ELoginPosition } from 'modules/gtmEvents/enums/login/ELoginPosition';
import { useRecoilState } from 'recoil';
import { loginPosition } from 'modules/state/gaAnalytics/login/state';

export const ButtonsBox: FC = () => {
    const [position] = useRecoilState<ELoginPosition>(loginPosition);

    return (
        <Grid container spacing={1}>
            <Grid item xs={4}>
                <SocialLoginButton
                    {...{
                        ...SocialLoginConstant[ESocialLogin.GOOGLE],
                        position,
                    }}
                />
            </Grid>
            <Grid item xs={4}>
                <SocialLoginButton
                    {...{
                        ...SocialLoginConstant[ESocialLogin.FACEBOOK],
                        position,
                    }}
                />
            </Grid>
            <Grid item xs={4}>
                <SocialLoginButton
                    {...{
                        ...SocialLoginConstant[ESocialLogin.AZET],
                        position,
                    }}
                />
            </Grid>
        </Grid>
    );
};
