import { useEffect } from 'react';
import { usePathname, useSearchParams } from 'next/navigation';
import useLoading from 'modules/state/app/hook/useLoading';

interface IReturn {
    isLoading: boolean;
    stopLoading: () => void;
}

export const usePageLoaderListener = (): IReturn => {
    const { isLoading, stop: stopLoading } = useLoading();
    const pathname = usePathname();
    const params = useSearchParams();

    useEffect(() => {
        stopLoading();
    }, [pathname, params, stopLoading]);

    return {
        isLoading,
        stopLoading,
    };
};
