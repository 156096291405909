import { useMemo } from 'react';
import { useRecoilState } from 'recoil';
import {
    analyticsGtmEventQueueState,
    analyticsGtmTrackerState,
    analyticsLivemonitorEventQueueState,
    analyticsLivemonitorTrackerState,
    analyticsRtbHouseEventQueueState,
    analyticsRtbHouseTrackerState,
    analyticsFacebookPixelTrackerState,
    analyticsFacebookPixelEventQueueState,
    analyticsFacebookPixePageViewQueueState,
    analyticsGemiusTrackerState,
    analyticsGemiusPageViewQueueState,
} from 'modules/state/app/state';
import IAnalytics, {
    IGemiusPageView,
    IGtmEvent,
    ILivemonitorEvent,
    IRtbHouseEvent,
} from 'modules/analytics/IAnalytics';

const useAnalytics = (): IAnalytics => {
    const [ , setGtmTracker ] = useRecoilState(analyticsGtmTrackerState);
    const [ , setGtmEventQueue ] = useRecoilState(analyticsGtmEventQueueState);
    const [ , setRtbHouseTracker ] = useRecoilState(analyticsRtbHouseTrackerState);
    const [ , setRtbHouseEventQueue ] = useRecoilState(analyticsRtbHouseEventQueueState);
    const [ , setLivemonitorTracker ] = useRecoilState(analyticsLivemonitorTrackerState);
    const [ , setLivemonitorEventQueue ] = useRecoilState(analyticsLivemonitorEventQueueState);
    const [ , setFacebookPixelTracker ] = useRecoilState(analyticsFacebookPixelTrackerState);
    const [ , setFacebookPixelEventQueue ] = useRecoilState(analyticsFacebookPixelEventQueueState);
    const [ , setFacebookPixelPageViewQueue ] = useRecoilState(analyticsFacebookPixePageViewQueueState);
    const [ , setGemiusTracker ] = useRecoilState(analyticsGemiusTrackerState);
    const [ , setGemiusPageViewQueue ] = useRecoilState(analyticsGemiusPageViewQueueState);

    return useMemo(() => ({
        gtm: {
            set: setGtmTracker,
            sendEvent: (...events: IGtmEvent[]): void => {
                setGtmEventQueue(queue => ([...queue, ...events]));
            },
        },
        facebookPixel: {
            set: setFacebookPixelTracker,
            sendEvent: (params: unknown[]): void => {
                setFacebookPixelEventQueue(queue => ([...queue, params]));
            },
            pageView: (): void => {
                setFacebookPixelPageViewQueue(queue => ([...queue, 1]));
            },
        },
        rtbHouse: {
            set: setRtbHouseTracker,
            sendEvent: (event: IRtbHouseEvent): void => {
                setRtbHouseEventQueue(queue => ([...queue, event]));
            },
        },
        livemonitor: {
            set: setLivemonitorTracker,
            sendEvent: (event: ILivemonitorEvent): void => {
                setLivemonitorEventQueue(queue => ([...queue, event]));
            },
        },
        gemius: {
            set: setGemiusTracker,
            pageView: (pageView: IGemiusPageView): void => {
                setGemiusPageViewQueue(queue => ([...queue, pageView]));
            },
        },
        hotjar: {
            sendEvent: (event: string): void => {
                const hj = () => {
                    // @ts-ignore
                    if (!window.hj) return;

                    // @ts-ignore
                    window.hj('event', event);
                };

                hj();
            },
        },
    }), [
        setGtmTracker,
        setGtmEventQueue,
        setRtbHouseTracker,
        setRtbHouseEventQueue,
        setLivemonitorTracker,
        setLivemonitorEventQueue,
        setFacebookPixelTracker,
        setFacebookPixelEventQueue,
        setFacebookPixelPageViewQueue,
        setGemiusTracker,
        setGemiusPageViewQueue,
    ]);
};

export default useAnalytics;
