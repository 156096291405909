import { ELoginPosition } from 'modules/gtmEvents/enums/login/ELoginPosition';

export const LoginPositionRecords: Record< string, ELoginPosition > = {
    ['header']: ELoginPosition.HEADER,
    ['search agent']: ELoginPosition.SEARCH_AGENT,
    ['favorite']: ELoginPosition.FAVORITE,
    ['comparison']: ELoginPosition.COMPARISON,
    ['homepage']: ELoginPosition.HOMEPAGE,
    ['contracts']: ELoginPosition.CONTRACTS,
    ['dsti']: ELoginPosition.DSTI,
    ['valuation']: ELoginPosition.VALUATION,
    ['property manager']: ELoginPosition.PROPERTY_MANAGER,
    ['market reports']: ELoginPosition.MARKET_REPORTS,
    ['onetap']: ELoginPosition.ONE_TAP,
    ['one tap']: ELoginPosition.ONE_TAP,
};
