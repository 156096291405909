import { FC, useEffect } from 'react';
import { deleteCookie, getCookie } from 'cookies-next';

export const CookieSetter: FC = () => {
    useEffect(() => {
        if (getCookie('next-enabled-listing')) {
            deleteCookie('next-enabled-listing');
        }
    }, []);

    return null;
};
