import { FC } from 'react';
import NextImage, { ImageProps/*, ImageLoader*/ } from 'next/image';

const Image: FC<ImageProps> = (props) => {
    // const skipOptimization =
    //     typeof props.src === 'string' &&
    //     (props.src.includes('s.nehnutelnostisk.work') ||
    //         props.src.includes('s.nehnutelnostisk.review') ||
    //         props.src.includes('s.nehnutelnosti.sk') ||
    //         props.src.includes('s.unitedclassifieds.work') ||
    //         props.src.includes('s.unitedclassifieds.review') ||
    //         props.src.includes('s.unitedclassifieds.sk') ||
    //         props.src.includes('img.nehnutelnostisk.work') ||
    //         props.src.includes('img.nehnutelnostisk.review') ||
    //         props.src.includes('img.nehnutelnosti.sk') ||
    //         props.src.includes('img.unitedclassifieds.work') ||
    //         props.src.includes('img.unitedclassifieds.review') ||
    //         props.src.includes('img.unitedclassifieds.sk') ||
    //         props.src.includes('t1.aimg.alfa') ||
    //         props.src.includes('t2.aimg.alfa') ||
    //         props.src.includes('t3.aimg.alfa') ||
    //         props.src.includes('t4.aimg.alfa') ||
    //         props.src.includes('t5.aimg.alfa') ||
    //         props.src.includes('t1.aimg.sk') ||
    //         props.src.includes('t2.aimg.sk') ||
    //         props.src.includes('t3.aimg.sk') ||
    //         props.src.includes('t4.aimg.sk') ||
    //         props.src.includes('t5.aimg.sk') ||
    //         props.src.includes('picsum.photos') ||
    //         props.src.includes('www.youtube.com') ||
    //         props.src.includes('my.matterport.com'));
    // TODO same domains in next.config.js

    // let loader: ImageLoader;

    // if (skipOptimization) {
    //     loader = (resolverProps) => resolverProps.src;
    // }

    return <NextImage {...props} unoptimized />;
};

export default Image;
