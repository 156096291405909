import {Box, SxProps, Theme, useTheme} from '@mui/material';
import {FC, MouseEventHandler, AnchorHTMLAttributes, ReactNode} from 'react';

interface IProps {
    href: string;
    ariaLabel?: string;
    sx?: SxProps<Theme>;
    disabled?: boolean;
    openInNewTab?: boolean;
    noUnderline?: boolean;
    children: ReactNode | ReactNode[];
    onClick?: MouseEventHandler<HTMLAnchorElement>;
    zIndex?: number;
    rels?: string[];
    tabIndex?: number;
}

const Link: FC<IProps> = ({
    href,
    ariaLabel,
    sx,
    openInNewTab,
    disabled,
    noUnderline,
    children,
    onClick,
    zIndex,
    rels = [],
    tabIndex,
}) => {
    const theme = useTheme();
    const sxResult = typeof sx === 'function' ? sx(theme) : sx;

    const props: AnchorHTMLAttributes<HTMLAnchorElement> = {
        href,
        onClick,
        tabIndex,
    };

    let finalRels = [...rels];

    if (openInNewTab && !rels.includes('')) {
        finalRels.push('noreferrer');
    }

    finalRels = finalRels.filter((value, index, array) => {
        return array.indexOf(value) === index;
    });

    if (openInNewTab) {
        props.target = '_blank';
    }

    props.rel = finalRels.length ? finalRels.join(' ') : undefined;

    return <Box
        component={disabled ? 'span' : 'a'}
        aria-label={ariaLabel}
        zIndex={zIndex}
        sx={{
            color: 'inherit',
            textDecoration: 'none',
            '&:hover': {
                textDecoration: noUnderline ? 'none' : 'underline',
            },
            ...sxResult
        }}
        {...props}
    >
        {children}
    </Box>;
};

export default Link;
