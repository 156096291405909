'use client';

import { FC, ReactNode } from 'react';
import { styled } from '@mui/material';
import { RecoilRoot, RecoilRootProps } from 'recoil';

type IRecoilRootProps =  RecoilRootProps & { children: ReactNode | ReactNode[] };
export const RecoilRootTyped = styled(RecoilRoot)<IRecoilRootProps>``;

export const RecoilStateProvider: FC<IRecoilRootProps> = ({ children }) => {
    return <RecoilRootTyped>
        {children}
    </RecoilRootTyped>;
};