import React, { FC } from 'react';
import {Desktop, Mobile} from 'modules/theme/components/responsive';
import { Login as MobileLogin } from 'components/layout/header/components/navbar/common/links/user/loggedOut/mobile/Login';
import { Login as DektopLogin } from 'components/layout/header/components/navbar/common/links/user/loggedOut/desktop/Login';

export const SocialLogin: FC = () => {
    return (
        <>
            <Desktop>
                <DektopLogin text="Prihlásiť sa" />
            </Desktop>

            <Mobile>
                <MobileLogin />
            </Mobile>
        </>
    );
};
