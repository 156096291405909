import { FC, ReactNode } from 'react';
import {Container} from '@mui/material';

interface IMain {
    children: ReactNode | ReactNode[];
}

const Main: FC<IMain> = ({ children }) => {
    return <Container maxWidth={false} disableGutters={true} sx={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
    }}>
        {children}
    </Container>;
};

export default Main;
