import {FC} from 'react';
import {FormHelperText} from '@mui/material';
import {RadioBoxGroup} from 'modules/theme/components/fields/radioBox/RadioBoxGroup';
import {reasons} from 'components/layout/footer/hooks/useSendSuggestion';

interface IOptions {
    value: string;
    errorMsg?: string;
    onChange: (value: string) => void;
}


export const Options: FC<IOptions> = ({errorMsg, value, onChange}) => {

    return <>
        <RadioBoxGroup
            value={value}
            options={reasons}
            onChange={onChange}
        />

        {errorMsg &&
            <FormHelperText error>
                {errorMsg}
            </FormHelperText>
        }
    </>;
};
