'use client';

import { FC } from 'react';
import useOnLogin from 'modules/page/app/useOnLogin';
import {useSuccessLoginEventListener} from 'modules/page/app/useSuccessLoginEventListener';
import {useUserRegistration} from 'modules/page/app/useUserRegistration';

const OnLoginListener: FC = () => {
    useOnLogin();
    useUserRegistration();
    useSuccessLoginEventListener();

    return null;
};

export default OnLoginListener;
