import {FC} from 'react';
import {Text} from 'modules/theme/components/text/Text';

export const Description: FC = () => {
    return <Text variant="body2">
        Ďakujeme za Vašu pomoc pri zlepšovaní služieb Nehnutelnosti.sk.
        Vaše pripomienky alebo nápady môžu byť anonymné, vážime si však ľudí, ktorí uvedú spätný kontakt a
        pomôžu nám tak pri skvalitňovaní služieb.
    </Text>;
};
