import {useRecoilState} from 'recoil';
import ETheme from 'modules/theme/state/ETheme';
import {themeAtom} from 'modules/theme/state/atom';

interface IReturn {
    theme: ETheme;
    setTheme: (theme: ETheme) => void;
}

const useTheme = (): IReturn => {
    const [theme, setTheme] = useRecoilState(themeAtom);

    return {
        theme,
        setTheme,
    };
};

export default useTheme;
