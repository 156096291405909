import { useEffect } from 'react';
import { getCookie, setCookie, deleteCookie } from 'cookies-next';
import useSession from 'modules/state/app/hook/session/useSession';
import useAnalytics from 'modules/state/app/hook/analytics/useAnalytics';
const COOKIE_NAME = 'oauth_login_event';
const COOKIE_PATH = '/';

const useOnLogin = (): void => {
    const { session, isLoading } = useSession();
    const { gtm: { sendEvent } } = useAnalytics();

    useEffect(() => {
        if (isLoading) {
            return;
        }

        if (session) {
            if (!getCookie(COOKIE_NAME, { path: COOKIE_PATH })){
                const expires = new Date();
                expires.setTime(expires.getTime() + (365*24*60*60*1000));

                sendEvent({
                    'prihlasenie': 'uspesne-prihlasenie',
                    'event': 'track-event',
                });

                setCookie(COOKIE_NAME, '1', { path: COOKIE_PATH, expires: expires });
            }
        } else{
            deleteCookie(COOKIE_NAME, { path: COOKIE_PATH });
        }
    }, [session, isLoading, sendEvent]);
};

export default useOnLogin;
