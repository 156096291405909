import React, { FC } from 'react';
import {Desktop, Mobile} from 'modules/theme/components/responsive';
import { AppConfigService } from 'modules/appConfig/AppConfigService';
import { AddAdvertiser as MobileAddAdvertisement } from 'components/layout/header/components/navbar/common/links/addAdvertisement/mobile/AddAdvertiser';
import { AddAdvertiser as DektopAddAdvertisement } from 'components/layout/header/components/navbar/common/links/addAdvertisement/desktop/AddAdvertiser';

interface IAddAdvertiser {
    isLoggedIn: boolean;
}

export const AddAdvertiser: FC<IAddAdvertiser> = ({ isLoggedIn }) => {
    let href = isLoggedIn ? 'pridanie' : 'pridanie-neprihlaseny';
    href = `${AppConfigService.getNehnutelnostiUrl()}/${href}`;

    return (
        <>
            <Desktop>
                <DektopAddAdvertisement href={href} text="Pridať inzerát" />
            </Desktop>

            <Mobile>
                <MobileAddAdvertisement href={href} />
            </Mobile>
        </>
    );
};
