import { useEffect } from 'react';
import { useRouter } from 'next/navigation';
import useSession from 'modules/state/app/hook/session/useSession';

export const useUserRegistration = (): void => {
    const router = useRouter();
    const { session, isLoading } = useSession();

    useEffect(() => {
        if (isLoading) {
            return;
        }

        if (!session) {
            return;
        }

        if (session.registrationCompletionUrl) {
            router.push(session.registrationCompletionUrl);
            return;
        }

        if (session.acceptGdprConditionsUrl) {
            router.push(session.acceptGdprConditionsUrl);
            return;
        }
    }, [isLoading, router, session]);
};
