interface ICounts {
    observer: number;
    searchAgent: number;
}

interface ILinks {
    gdprExport: string;
    gdprAnonymize: string;
}


export enum EAuthType {
    AZET = 20,
    FACEBOOK = 21,
    GOOGLE = 22,
}

export const authTypeToName: Record<EAuthType, string> = {
    [EAuthType.AZET]: 'Azet ID',
    [EAuthType.FACEBOOK]: 'Facebook konto',
    [EAuthType.GOOGLE]: 'Google konto',
};

export default interface ISession {
    id?: string;
    authType: EAuthType;
    nick?: string;
    email?: string;
    phone?: string;
    counts: ICounts;
    links: ILinks;
    isAdmin: boolean;
    lastDtiId?: number;
    registrationCompletionUrl?: string;
    acceptGdprConditionsUrl?: string;
    defaultSettingsFrom?: string;
}