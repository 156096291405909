import { EGtmEvent } from 'modules/gtmEvents/enums/EGtmEvent';
import { ELabel } from 'modules/gtmEvents/enums/header/ELabel';
import { EAction } from 'modules/gtmEvents/enums/header/EAction';
import { ECategory } from 'modules/gtmEvents/enums/header/ECategory';
import { IHeaderTrackEvent } from 'modules/gtmEvents/interfaces/header/IHeaderTrackEvent';

export const getAddAdvertisementEvent = (
    isLoggedIn?: boolean,
    action: EAction = EAction.HP
): IHeaderTrackEvent => {
    return {
        action,
        label: ELabel.ADD,
        category: ECategory.HEADER,
        event: EGtmEvent.TRACK_EVENT,
        is_loggedin: isLoggedIn ? 'true' : 'undefined',
    };
};

export const getLoginEvent = (
    isLoggedIn?: boolean,
    action: EAction = EAction.HP
): IHeaderTrackEvent => {
    return {
        action,
        label: ELabel.LOGIN,
        category: ECategory.HEADER,
        event: EGtmEvent.TRACK_EVENT,
        is_loggedin: isLoggedIn ? 'true' : 'undefined',
    };
};
