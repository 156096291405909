import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { loadingCountState } from 'modules/state/app/state';

interface IReturn {
    isLoading: boolean;
    start: () => void;
    stop: () => void;
}

const useLoading = (): IReturn => {
    const [ count, setCount ] = useRecoilState(loadingCountState);

    const start = useCallback(
        () => {
            setCount(count => count + 1);
        },
        [setCount]
    );

    const stop = useCallback(
        () => {
            setCount(count => Math.max(count - 1, 0));
        },
        [setCount]
    );

    return {
        isLoading: count > 0,
        start,
        stop
    };
};

export default useLoading;
