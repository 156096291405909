import {FC} from 'react';
import {Container, Grid, AppBar, Box} from '@mui/material';
import {Copyright} from 'components/layout/footer/components/links/Copyright';
import {BrandLogoLink} from 'components/layout/footer/components/links/BrandLogoLink';
import {IconLinks} from 'components/layout/footer/components/links/iconLink/IconLinks';
import {TextLinks} from 'components/layout/footer/components/links/textLink/TextLinks';


const Footer: FC = () => {

    return <AppBar variant="footer" position='sticky'>

        <Box pt={4} pb={2}>
            <Container maxWidth="lg">
                <Grid container spacing={2} alignItems="flex-start">

                    <Grid item xs={12} lg={3}>
                        <Grid container>
                            <Grid item xs={6} lg={12}>

                                <BrandLogoLink/>

                                <Box display={{xs: 'none', xl: 'block'}}>
                                    <Box mt={8}>
                                        <Copyright/>
                                    </Box>
                                </Box>

                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} lg={6}>
                        <TextLinks/>
                    </Grid>

                    <Grid item xs={12} lg={3}>
                        <IconLinks/>
                    </Grid>

                    <Grid item xs={12}>
                        <Box display={{xs: 'block', xl: 'none'}}>
                            <Copyright/>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    </AppBar>;
};

export default Footer;
