import {useState, useCallback} from 'react';
import Menu from 'components/layout/header/components/navbar/common/mobile/menu/Menu';

interface IReturn {
    isOpen: boolean;
    toggle: () => void;
    component: JSX.Element;
}

export const useMobileMenu = (): IReturn => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const toggle = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen]);

    return {
        isOpen,
        toggle,
        component: <Menu
            isOpen={isOpen}
            onClose={() => {
                setIsOpen(false);
            }}
        />
    };

};
