import ISession, {EAuthType} from 'modules/session/interfaces/ISession';
import {SessionDto, SessionDtoAuthTypeEnum} from 'modules/api/generated';

const EAuthTypeApiClientMapper = (apiEnum: SessionDtoAuthTypeEnum): EAuthType => {
    switch (apiEnum) {
    case SessionDtoAuthTypeEnum.NUMBER_20:
        return EAuthType.AZET;
    case SessionDtoAuthTypeEnum.NUMBER_21:
        return EAuthType.FACEBOOK;
    case SessionDtoAuthTypeEnum.NUMBER_22:
        return EAuthType.GOOGLE;
    }
};


export const SessionDtoToISessionMapper = (sessionDto: SessionDto): ISession => {

    const {authType, counts, links, isAdmin, lastDtiId} = sessionDto;

    return {
        id: sessionDto.id,
        nick: sessionDto.nick,
        email: sessionDto.email,
        phone: sessionDto.phone,
        counts,
        links,
        isAdmin,
        lastDtiId: lastDtiId || null,
        authType: EAuthTypeApiClientMapper(authType),
        registrationCompletionUrl: sessionDto.registrationCompletionUrl || null,
        acceptGdprConditionsUrl: sessionDto.acceptGdprConditionsUrl || null,
    };

};
