/* eslint-disable */
import { ElementType, FC, ReactNode } from 'react';
import { OverridableStringUnion } from '@mui/types';
import { Variant } from '@mui/material/styles/createTypography';
import { SimplePaletteColorOptions } from '@mui/material/styles/createPalette';
import {
    Palette,
    Typography,
    TypographyProps,
    useTheme,
} from '@mui/material';
import { TypographyPropsVariantOverrides } from '@mui/material/Typography/Typography';

interface IText {
    id?: string;
    mr?: TypographyProps['mr'];
    mb?: TypographyProps['mb'];
    bold?: boolean;
    noWrap?: boolean;
    preWrap?: boolean;
    hidden?: boolean;
    italic?: boolean;
    center?: boolean;
    semibold?: boolean;
    underLine?: boolean;
    children?: ReactNode;
    inheritColor?: boolean;
    inverseColor?: boolean;
    component?: ElementType;
    textTransform?: 'uppercase' | 'lowercase' | 'capitalize';
    variant: OverridableStringUnion<Variant, TypographyPropsVariantOverrides>;
    mobileVariant?: OverridableStringUnion<
        Variant,
        TypographyPropsVariantOverrides
    >;
    color?:
        | SimplePaletteColorOptions
        | ((palette: Palette) => SimplePaletteColorOptions);
    testId?: string;
    lineClamp?: number;
}

export const Text: FC<IText> = ({
    id,
    mr,
    mb,
    bold,
    color,
    hidden,
    noWrap,
    preWrap,
    center,
    italic,
    variant,
    semibold,
    children,
    underLine,
    inheritColor,
    inverseColor,
    textTransform,
    mobileVariant,
    component = 'p',
    testId = 'text',
    lineClamp,
}) => {
    const { palette } = useTheme();
    const textColor = typeof color === 'function' ? color(palette) : color;

    const defaultColor = inheritColor
        ? 'inherit'
        : inverseColor
        ? 'labelPrimaryInverse.main'
        : 'labelPrimary.main';

    let fontWeight = 'regular';
    if (bold) fontWeight = 'bold';
    if (semibold) fontWeight = 'medium';

    if (bold && semibold) {
        throw Error(
            'In <Text> component it is not possible to use bold and semibold at the same time'
        );
    }

    if (color && inverseColor) {
        throw Error(
            'Use inverse palette."inverseVariant" instead of color and inverseColor at the same time'
        );
    }

    if (lineClamp || noWrap || preWrap) {
        let errorMessage = (primary, secondary) => {
            throw Error(
                `In <Text> component it is not possible to use ${primary} and ${secondary} at the same time`
            )
        };
        switch (true) {
            case (lineClamp && noWrap):
                errorMessage(lineClamp, noWrap);
                break;
            case (noWrap && preWrap):
                errorMessage(noWrap, preWrap);
                break;
        }
    }

    const lineClampStyles = {
        display: '-webkit-box',
        overflow: 'hidden',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: lineClamp,
    };

    return (
        <Typography
            id={id}
            mr={mr}
            mb={mb}
            noWrap={noWrap}
            component={component}
            fontWeight={fontWeight}
            display={hidden && 'none'}
            textTransform={textTransform}
            fontStyle={italic && 'italic'}
            color={textColor?.main || defaultColor}
            variant={mobileVariant ? mobileVariant : variant}
            sx={(theme) => ({
                '&': { // important because without & rewrite mr mb
                    textDecoration: underLine ? 'underline' : 'none',
                    textAlign: center && 'center',
                    ...(lineClamp ? lineClampStyles : {}),
                    ...(preWrap ? {whiteSpace: 'pre-wrap'} : {}),
                    ...(mobileVariant ? {
                        [theme.breakpoints.up('md')]: {
                            ...theme.typography[variant],
                            fontWeight,
                        }
                    } : {}),
                }
            })}
            data-test-id={testId}
        >
            {children}
        </Typography>
    );
};
