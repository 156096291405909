import { FC } from 'react';
import { Palette } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';
import useRecaptcha from 'components/recaptcha/hooks/useRecaptcha';

interface IRecaptcha {
  errorMsg?: string;
  onChange: (token: string) => void;
}

export const Recaptcha: FC<IRecaptcha> = ({ onChange, errorMsg }) => {
    const {
        component: recaptchaComponent,
        hiddenInputComponent: recaptchaHiddenInputComponent,
    } = useRecaptcha({
        hiddenInputName: 'recaptchaToken',
        onChange: (token) => {
            onChange(token);
        },
    });

    return (
        <>
            {recaptchaComponent}
            {recaptchaHiddenInputComponent}

            {errorMsg && (
                <Text variant="label2" color={(palette: Palette) => palette.error}>
                    {errorMsg}
                </Text>
            )}
        </>
    );
};
