import { useRecoilState } from 'recoil';
import { usePathname } from 'next/navigation';
import { Stack, useTheme } from '@mui/material';
import React, { FC, useMemo, useState } from 'react';
import { IGtmEvent } from 'modules/analytics/IAnalytics';
import { appUserIsLoggedIn } from 'modules/state/app/state';
import useAnalytics from 'modules/state/app/hook/analytics/useAnalytics';
import { getLoginEvent } from 'modules/gtmEvents/helpers/events/common/headerEventsHelper';
import { PathNameToActionRecord } from 'modules/gtmEvents/records/header/PathNameToActionRecord';
import { getResetEcommerceEvent } from 'modules/gtmEvents/helpers/events/common/commonEventsHelper';
import { Drawer } from 'components/layout/header/components/navbar/common/links/user/loggedOut/mobile/drawer/Drawer';
import { LoginIcon } from 'components/layout/header/components/navbar/common/links/user/loggedOut/mobile/icon/LoginIcon';
import { Content } from 'components/layout/header/components/navbar/common/links/user/loggedOut/partials/content/Content';

export const Login: FC = () => {
    const { palette } = useTheme();

    const [open, setOpen] = useState(false);
    const pathname = usePathname();
    const { gtm: { sendEvent: gtmSendEvent }} = useAnalytics();
    const [isAdvertiserLoggedIn] = useRecoilState(appUserIsLoggedIn);

    const action = useMemo(() => PathNameToActionRecord[pathname], [pathname]);

    const handleClick = () => {
        if (!open) {
            const events: IGtmEvent[] = [
                getResetEcommerceEvent(),
                getLoginEvent(isAdvertiserLoggedIn, action),
            ];

            gtmSendEvent(...events);
        }

        setOpen(!open);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Stack
                p={0.5}
                borderRadius={10}
                alignItems="center"
                justifyContent="center"
                sx={{
                    '&:active': {
                        bgcolor: palette.backgroundSecondary.main,
                    },
                }}
                onClick={handleClick}
            >
                <LoginIcon />
            </Stack>
            <Drawer isOpen={open} onClose={handleClose}>
                <Content onClose={handleClose} />
            </Drawer>
        </>
    );
};
