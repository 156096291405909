export function encodeJson<T> (data: T): string {
    return encodeURIComponent(
        Buffer.from(
            JSON.stringify(data)
        ).toString('base64')
    );
}

export function decodeJson<T> (data: string): T {
    return JSON.parse(
        Buffer.from(
            decodeURIComponent(data),
            'base64'
        ).toString()
    );
}