import { useCallback } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useSnackbar } from 'notistack';
import {FlashMessageContent} from 'modules/theme/components/flashMessage/SnackbarFlashMessageContent';

interface IReturn {
    success: (message: string) => void;
    info: (message: string) => void;
    error: (message: string) => void;
}

const useNotification = (): IReturn => {
    const { enqueueSnackbar } = useSnackbar();

    const success = useCallback(
        (message: string) => {
            enqueueSnackbar(message, {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                content: (key, snackMessage) => (
                    <FlashMessageContent
                        variant={'success'}
                        message={snackMessage}
                    />
                ),
            }
            );
        },
        [enqueueSnackbar]
    );

    const info = useCallback(
        (message: string) => {
            enqueueSnackbar(message, {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                content: (key, snackMessage) => (
                    <FlashMessageContent
                        variant={'info'}
                        message={snackMessage}
                    />
                ),
            }
            );
        },
        [enqueueSnackbar]
    );

    const error = useCallback(
        (message: string) => {
            enqueueSnackbar(message, {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                content: (key, snackMessage) => (
                    <FlashMessageContent
                        variant={'error'}
                        message={snackMessage}
                    />
                ),
            }
            );
        },
        [enqueueSnackbar]
    );


    return {
        success,
        info,
        error,
    };
};

export default useNotification;
