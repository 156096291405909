/* eslint-disable */
import {FC} from 'react';
import {Svg} from 'modules/theme/components/svg/Svg'
import {FormControlLabel, Radio} from '@mui/material';
import DefaultIcon from 'modules/theme/components/fields/radioBox/svg/defaultIcon.svg';
import CheckedIcon from 'modules/theme/components/fields/radioBox/svg/checkedIcon.svg';

interface IRadioBox {
    value: string;
    label: string;
    disabled?: boolean;
    isChecked: boolean;
    onChange: (value: string) => void;
}

export const RadioBox: FC<IRadioBox> = ({
    value,
    label,
    disabled,
    onChange,
    isChecked,
}) => {
    return (
        <FormControlLabel
            value={value}
            label={label}
            disabled={disabled}
            checked={isChecked}
            onChange={() => onChange(value)}
            data-test-id="radioBox"
            control={
                <Radio
                    icon={<Svg icon component={DefaultIcon} width={18} />}
                    checkedIcon={<Svg icon component={CheckedIcon} width={18} />}
                />
            }
        />
    );
};
