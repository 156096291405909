import { EAction } from 'modules/gtmEvents/enums/header/EAction';

export const PathNameToActionRecord: Record<string, EAction> = {
    ['/']: EAction.HP,

    ['/detail']: EAction.ADV_DETAIL,
    ['/vysledky']: EAction.ADV_LISTING,

    ['/realitne-kancelarie']: EAction.REAL_ESTATE,
    ['/realitna-kancelaria']: EAction.REAL_ESTATE,
    ['/realEstate/agency/redirects']: EAction.REAL_ESTATE,
    ['/realitni-makleri']: EAction.REAL_ESTATE,

    ['/sluzby']: EAction.SERVICES,
};
