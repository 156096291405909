/* eslint-disable */
import { FC } from 'react';
import { RadioGroup } from '@mui/material';
import { RadioBox } from 'modules/theme/components/fields/radioBox/RadioBox';
import { IOption } from 'modules/theme/components/fields/radioBox/interfaces/IOption';

interface IRadioBoxGroup {
    value: string;
    options: IOption[];
    disabled?: boolean;
    onChange: (value: string) => void;
    testId?: string;
    row?: boolean;
}

export const RadioBoxGroup: FC<IRadioBoxGroup> = ({
    value,
    options,
    disabled,
    onChange,
    testId = 'radioBoxGroup',
    row = true,
}) => {
    return (
        <RadioGroup row={row} data-test-id={testId}>
            {options.map((option) => (
                <RadioBox
                    disabled={disabled}
                    label={option.name}
                    value={option.value}
                    onChange={onChange}
                    key={option.name + option.value}
                    isChecked={value === option.value}
                />
            ))}
        </RadioGroup>
    );
};
