import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { openMenuState } from 'modules/state/app/state';

export interface IOpenState {
    [key: string]: boolean;
}

interface IReturn {
    open: (id: string) => void;
    close: (id: string) => void;
    isOpen: (id: string) => boolean;
}

const useOpenMenuState = (): IReturn => {
    const [ openState, setOpenState ] = useRecoilState(openMenuState);

    const open = useCallback((id: string) => {
        const newOpenState = { ... openState };
        for (const i in newOpenState) {
            newOpenState[i] = false;
        }
        newOpenState[id] = true;

        setOpenState(newOpenState);
    }, [openState, setOpenState]);

    const close = useCallback((id: string) => {
        const newOpenState = { ... openState };
        newOpenState[id] = false;

        setOpenState(newOpenState);
    }, [openState, setOpenState]);

    const isOpen = useCallback((id: string): boolean => {
        return openState[id] || false;
    }, [openState]);

    return {
        open,
        close,
        isOpen
    };
};

export default useOpenMenuState;
