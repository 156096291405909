import { EGtmEvent } from 'modules/gtmEvents/enums/EGtmEvent';
import { ISuccessLoginEvent } from 'modules/gtmEvents/interfaces/login/ISuccessLoginEvent';
import { ELoginMethod } from 'modules/gtmEvents/enums/login/ELoginMethod';
import { ELoginPosition } from 'modules/gtmEvents/enums/login/ELoginPosition';
import { EFormState } from 'modules/gtmEvents/enums/EFormState';

export const getSuccessLoginEvent = (method: ELoginMethod, position: ELoginPosition): ISuccessLoginEvent => {
    return {
        event: EGtmEvent.LOGIN,
        method,
        position,
        form_state: EFormState.SUCCESS,
    };
};
