import { useRecoilState } from 'recoil';
import ISession from 'modules/session/interfaces/ISession';
import { isSessionLoadingState, sessionState } from 'modules/state/app/state';

interface IReturn {
    isLoading: boolean;
    session?: ISession;
}

const useSession = (): IReturn => {
    const [ data ] = useRecoilState(sessionState);
    const [ isLoading ] = useRecoilState(isSessionLoadingState);

    return {
        isLoading,
        session: data
    };
};

export default useSession;
