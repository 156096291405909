import React, { FC } from 'react';
import { Text } from 'modules/theme/components/text/Text';
import { Avatar, Stack, Palette, useTheme } from '@mui/material';
import { Wrapper } from 'components/layout/header/components/navbar/common/links/user/loggedIn/wrapper/Wrapper';

interface IMyAccount {
    nickName: string;
    href: string;
}

export const MyAccount: FC<IMyAccount> = ({ nickName, href }) => {
    const { palette } = useTheme();
    const initials = nickName.toUpperCase().slice(0, 2);

    return (
        <Wrapper href={href}>
            <Stack alignItems="center" justifyContent="center" display="flex">
                <Avatar
                    sx={{
                        width: 26,
                        height: 26,
                        bgcolor: '#707070',
                        '&:active': {
                            bgcolor: palette.labelPrimary.main,
                        },
                    }}
                >
                    <Text
                        semibold
                        variant="label2"
                        color={(palette: Palette) =>
                            palette.labelPrimaryInverse
                        }
                    >
                        {initials}
                    </Text>
                </Avatar>
            </Stack>
        </Wrapper>
    );
};
