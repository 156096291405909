import {FC} from 'react';
import Link from 'components/link/Link';
import {Text} from 'modules/theme/components/text/Text';

export const Copyright: FC = () => {
    return <Text variant="label2" inverseColor>
        © 2015 - {new Date().getFullYear()}&nbsp;

        <Link href="https://www.unitedclassifieds.sk/" openInNewTab>
            United Classifieds s.r.o.
        </Link>
    </Text>;
};

