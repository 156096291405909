'use client';

import { FC, useEffect } from 'react';
import Auth, {Config} from '@uc-platform/auth';
import {AppConfigService} from 'modules/appConfig/AppConfigService';

export const AuthInit: FC = () => {

    useEffect(() => {
        const jssStyles = document.querySelector('#jss-server-side');
        if (jssStyles && jssStyles.parentElement) {
            jssStyles.parentElement.removeChild(jssStyles);
        }

        Auth.init(
            new Config(
                AppConfigService.getOauthApiUrl(),
                AppConfigService.getPlatformProjectName(),
                AppConfigService.getOauthCallbackUrl()
            )
        );

        try {
            if (window.opener && window.opener['ucAuthLoginDialogActivated']) {
                window.opener['ucAuthLoginDialogActivated'] = false;
                window.close();
                window.opener.location.reload(true);
                return;
            }
        } catch (error) {
            if (error instanceof DOMException) {
                return;
            }

            throw error;
        }
    }, []);

    return null;
};