import React, { FC, ReactNode } from 'react';
import useAnalytics from 'modules/state/app/hook/analytics/useAnalytics';
import { appUserIsLoggedIn } from 'modules/state/app/state';
import { useRecoilState } from 'recoil';
import Link from 'components/link/Link';
import { usePathname } from 'next/navigation';
import { getAddAdvertisementEvent } from 'modules/gtmEvents/helpers/events/common/headerEventsHelper';
import { PathNameToActionRecord } from 'modules/gtmEvents/records/header/PathNameToActionRecord';
import { EAction } from 'modules/gtmEvents/enums/header/EAction';
import {getResetEcommerceEvent} from 'modules/gtmEvents/helpers/events/common/commonEventsHelper';
import {IGtmEvent} from 'modules/analytics/IAnalytics';
export interface IWrapper {
    href: string;
    children: ReactNode | ReactNode[];
}

export const Wrapper: FC<IWrapper> = ({ href, children }) => {
    const pathname = usePathname();
    const {
        gtm: { sendEvent: gtmSendEvent },
    } = useAnalytics();

    const [isAdvertiserLoggedIn] = useRecoilState(appUserIsLoggedIn);
    const action: EAction = PathNameToActionRecord[pathname || ''];

    const handleOnClick = () => {
        const events: IGtmEvent[] = [
            getResetEcommerceEvent(),
            getAddAdvertisementEvent(isAdvertiserLoggedIn, action),
        ];

        gtmSendEvent(...events);
    };

    return (
        <Link onClick={handleOnClick} noUnderline href={href} ariaLabel='Add advertiser'>
            {children}
        </Link>
    );
};
