import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { headerLoaderState } from 'modules/state/app/state';

interface IReturn {
    percentage: number;
    setPercentage: (number: number) => void;
}

const useHeaderLoading = (): IReturn => {
    const [ percentage, setPercentage ] = useRecoilState<number>(headerLoaderState);

    const setLoadingPercentage =
          useCallback(
              (percentage: number) => {
                  if (percentage >= 0 && percentage <= 100) {
                      setPercentage(percentage);
                  }
              },
              [setPercentage]
          );
    
    
        
    return {
        percentage,
        setPercentage: setLoadingPercentage,
    };
};

export default useHeaderLoading;
