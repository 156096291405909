import {FC} from 'react';
import {Grid} from '@mui/material';
import Link from 'components/link/Link';
import {INDEX, REAL_ESTATE_LANDING_PAGE} from 'modules/route/routes';
import Logo from 'components/layout/header/svg/logo.svg';
import MenuItem from 'components/layout/header/components/navbar/common/menu/MenuItem';
import { Svg } from 'modules/theme/components/svg/Svg';
import {AppConfigService} from 'modules/appConfig/AppConfigService';

export const Navigation: FC = () => {
    const publicNehnutelnostiUrl = AppConfigService.getNehnutelnostiUrl();
    return <Grid item container>
        <Grid item>
            <Link href={INDEX} sx={{display: 'flex'}} ariaLabel={'Nehnutelnosti brand logotyp'}>
                <Svg icon component={Logo} width={210} height={30} color='labelPrimary' />
            </Link>
        </Grid>

        <Grid item>
            <MenuItem href={REAL_ESTATE_LANDING_PAGE} text="Realitné kancelárie" sx={{marginLeft: 3}}/>
            <MenuItem href={`${publicNehnutelnostiUrl}/magazin-o-byvani`} text="Magazín" sx={{marginLeft: 3}}/>

            <MenuItem text="Užitočné info" sx={{marginLeft: 3}}>
                <MenuItem href={`${publicNehnutelnostiUrl}/developerske-projekty-a-novostavby/`} text="Developerské projekty a novostavby" />
                <MenuItem href={`${publicNehnutelnostiUrl}/ocenovanie-nehnutelnosti/`} text="Ocenenie nehnuteľnosti"/>
                <MenuItem href={`${publicNehnutelnostiUrl}/hypoteka`} text="Hypotéky"/>
                <MenuItem href={`${publicNehnutelnostiUrl}/trhove-reporty`} text="Ceny realít"/>
                <MenuItem href={`${publicNehnutelnostiUrl}/informacie`} text="Zmluvy"/>
                <MenuItem href={`${publicNehnutelnostiUrl}/byvanie`} text="Katalóg bývania"/>
            </MenuItem>
        </Grid>
    </Grid>;
};
