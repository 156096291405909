import React, { FC } from 'react';
import { MyAccount } from 'components/layout/header/components/navbar/common/links/user/loggedIn/MyAccount';
import { SocialLogin } from 'components/layout/header/components/navbar/common/links/user/loggedOut/SocialLogin';

export interface IUser {
    isLoggedIn: boolean;
    nickName?: string;
}

export const User: FC<IUser> = ({ isLoggedIn, nickName }) => {
    return <>{isLoggedIn ? <MyAccount nickName={nickName || ''}/> : <SocialLogin />}</>;
};
