import React, {FC} from 'react';
// eslint-disable-next-line no-restricted-imports
import {SnackbarContent, SnackbarMessage} from 'notistack';
import {Alert, Box} from '@mui/material';
import {Text} from 'modules/theme/components/text/Text';
import CheckCircleOutlineIcon from 'modules/theme/components/icons/basic/CheckCircleOutline.svg';
import InfoIcon from 'modules/theme/components/icons/basic/Info.svg';
import ResetCircleIcon from 'modules/theme/components/icons/basic/ResetCircle.svg';
import {Svg} from 'modules/theme/components/svg/Svg';

interface FlashMessageContentProps {
    message: SnackbarMessage;
    variant: 'success' | 'info' | 'error';
}

export const FlashMessageContent: FC<FlashMessageContentProps> = React.forwardRef<HTMLDivElement, FlashMessageContentProps>((
    { message, variant }
    , ref) =>
{
    let icon: React.ReactNode;
    if (variant === 'success') {
        icon = <Svg icon component={CheckCircleOutlineIcon} width={24} height={24} />;
    }
    if (variant === 'info') {
        icon = <Svg icon component={InfoIcon} width={24} height={24} />;
    }
    if (variant === 'error') {
        icon = <Svg icon component={ResetCircleIcon} width={24} height={24} />;
    }

    return (
        <SnackbarContent
            ref={ref}
            style={{
                minWidth: '150px',
                justifyContent: 'center',
            }}
        >
            <Alert
                severity={variant}
                icon={icon}
                sx={{
                    '.MuiAlert-icon': {
                        marginRight: 1
                    }
                }}
            >
                <Box display={'flex'} gap={2}>
                    <Text variant={'body3'} semibold>
                        {message}
                    </Text>
                </Box>
            </Alert>
        </SnackbarContent>
    );
});

FlashMessageContent.displayName = 'FlashMessageContent';
