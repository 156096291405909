import {FC, useEffect} from 'react';
import Script from 'next/script';
import useAnalytics from 'modules/state/app/hook/analytics/useAnalytics';
import { IGemiusPageView } from 'modules/analytics/IAnalytics';

const Gemius: FC = () => {
    const { gemius: { set } } = useAnalytics();

    // TODO move this to onLoad
    useEffect(() => {
        set({
            pageView: (pageView: IGemiusPageView): void => {
                if (typeof window['pp_gemius_hit'] === 'function') {
                    window['pp_gemius_hit'](pageView.id);
                }
            },
        });
    }, [set]);

    return <Script
        id="script-analytics-gemius"
        strategy="afterInteractive"
    >
        {`
            var pp_gemius_use_cmp = true;
            var pp_gemius_extraparameters = new Array(
            "lan=EN",
            "key=keyword",
            "subs=subsection",
            "free=free_field"
            );
            // lines below shouldn't be edited
            function gemius_pending(i) {
            window[i] =
                window[i] ||
                function () {
                var x = (window[i + "_pdata"] = window[i + "_pdata"] || []);
                x[x.length] = arguments;
                };
            }
            gemius_pending("gemius_hit");
            gemius_pending("gemius_event");
            gemius_pending("gemius_init");
            gemius_pending("pp_gemius_hit");
            gemius_pending("pp_gemius_event");
            gemius_pending("pp_gemius_init");
            (function (d, t) {
            try {
                var gt = d.createElement(t),
                s = d.getElementsByTagName(t)[0],
                l = "http" + (location.protocol == "https:" ? "s" : "");
                gt.setAttribute("async", "async");
                gt.setAttribute("defer", "defer");
                gt.src=l+'://gask.hit.gemius.pl/xgemius.js'; 
                s.parentNode.insertBefore(gt, s);
            } catch (e) {}
            })(document, "script");
        `}
    </Script>;
};


export default Gemius;
