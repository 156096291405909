/* eslint-disable */
import { FC } from 'react';
import { TextField } from '@mui/material';

interface ITextArea {
    rows?: number;
    value: string;
    errMsg?: string;
    disabled?: boolean;
    placeholder?: string;
    onChange: (value: string) => void;
    testId?: string;
    onBlur?: () => void;
}

export const TextArea: FC<ITextArea> = ({
    value,
    errMsg,
    disabled,
    onChange,
    placeholder,
    rows = 1,
    testId = 'textArea',
    onBlur,
}) => {
    return (
        <TextField
            fullWidth
            multiline
            rows={rows}
            value={value}
            error={!!errMsg}
            helperText={errMsg}
            disabled={disabled}
            placeholder={placeholder}
            slotProps={{
                htmlInput: { 'aria-label': 'textarea' }
            }}
            onChange={(e) => onChange(e.target.value)}
            onBlur={() => {
                if (onBlur) {
                    onBlur();
                }
            }}
            data-test-id={testId}
        />
    );
};
