import { OverridableStringUnion } from '@mui/types';
import { FC, FunctionComponent, SVGProps } from 'react';
import { SvgIcon, SvgIconPropsColorOverrides, SxProps, Theme, styled } from '@mui/material';

export type SvgIconColor = OverridableStringUnion<| 'info'
    | 'error'
    | 'action'
    | 'inherit'
    | 'primary'
    | 'success'
    | 'disabled'
    | 'warning'
    | 'secondary',
    SvgIconPropsColorOverrides>;

const SvgRoot = styled('svg', {
    name: 'MuiSvg',
    slot: 'Root',
})(() => ({
    userSelect: 'none',
    display: 'inline-block',
    flexShrink: 0,
}));

interface ISvg {
    icon?: boolean;
    component: FunctionComponent<SVGProps<SVGSVGElement>>;
    width?: number | string;
    height?: number | string;
    color?: SvgIconColor;
    testId?: string;
    sx?: SxProps<Theme>;
    onIconClick?: () => void;
}

export const Svg: FC<ISvg> = ({ icon, component, color, width, height, testId, sx, onIconClick}) => {
    if (icon) {
        const wh: { width?: number | string, height?: number | string } = {
            ...(width ? { width: width } : {}),
            ...(height ? { height: height } : (width ? { height: width } : {}))
        };
        return (
            <SvgIcon
                onClick={onIconClick}
                color={color}
                inheritViewBox
                component={component}
                sx={{
                    ...wh,
                    ...sx
                }}
                data-test-id={testId}
                {...wh}
            />
        );
    }

    const wh: { width?: number | string, height?: number | string } = {
        ...(width ? { width: width } : {}),
        ...(height ? { height: height } : {})
    };

    return (
        <SvgRoot
            onClick={onIconClick}
            as={component}
            sx={{
                ...(onIconClick ? { cursor: 'pointer' } : {}),
                ...wh,
                ...sx,
            }}
            color={color}
            data-test-id={testId}
            {...wh}
        />
    );
};
