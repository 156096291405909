import * as yup from 'yup';
import IData from 'components/layout/footer/interfaces/IData';

const validationSchema = yup.object().shape<Record<keyof IData, yup.AnySchema>>({
    reason: yup
        .string()
        .required('Dôvod je povinný'),
    text: yup
        .string()
        .required('Text správy je povinný'),
    recaptchaToken: yup
        .string()
        .required('Je potrebné vyplniť reCAPTCHA')
});

export default validationSchema;
