import {FC} from 'react';
import Script from 'next/script';
import { AppConfigService } from 'modules/appConfig/AppConfigService';

const Hotjar: FC = () => {
    return <Script
        async={true}
        id="script-analytics-hotjar"
        strategy="afterInteractive"
        type='text/javascript'
    >
        {`
          (function (h, o, t, j, a, r) {
            h.hj =
              h.hj ||
              function () {
                // eslint-disable-next-line prefer-rest-params
                (h.hj.q = h.hj.q || []).push(arguments);
              };
            h._hjSettings = { hjid: ${AppConfigService.getHotjarAppId()}, hjsv: 6 };
            a = o.getElementsByTagName("head")[0];
            r = o.createElement("script");
            r.async = 1;
            r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
            a.appendChild(r);
          })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
        `}
    </Script>;
};


export default Hotjar;
