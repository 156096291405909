import {FC} from 'react';
import {Grid} from '@mui/material';
import iconFb from 'components/layout/footer/png/fb.png';
import iconMail from 'components/layout/footer/png/mail.png';
import iconLightbulb from 'components/layout/footer/png/lightbulb.png';
import {Item} from 'components/layout/footer/components/links/iconLink/Item';
import {useSendSuggestion} from 'components/layout/footer/hooks/useSendSuggestion';
import {AppConfigService} from 'modules/appConfig/AppConfigService';

export const IconLinks: FC = () => {

    const {
        dialogComponent: sendSuggestionDialogComponent,
        send: sendSuggestion
    } = useSendSuggestion();

    const iconLinks = [
        {
            name: 'Kontakt',
            icon: iconMail,
            path: `${AppConfigService.getNehnutelnostiUrl()}/kontakty`
        },
        {
            name: 'Pripomienky',
            icon: iconLightbulb,
            path: '#',
            onClick: () => sendSuggestion()
        },
        {
            name: 'Nehnuteľnosti na Facebooku',
            icon: iconFb,
            path: 'https://www.facebook.com/nehnutelnosti.sk'
        },
    ];

    return <>
        {sendSuggestionDialogComponent}

        <Grid container spacing={1}>

            {iconLinks.map(link => (
                <Grid item lg={12} key={link.name + link.path}>
                    <Item href={link.path} name={link.name} onClick={link.onClick} icon={link.icon}/>
                </Grid>
            ))}

        </Grid>
    </>;
};
