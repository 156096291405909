export enum ELoginPosition {
    HEADER = 'header',
    SEARCH_AGENT = 'search agent',
    FAVORITE = 'favorite',
    COMPARISON = 'comparison',
    HOMEPAGE = 'homepage',
    CONTRACTS = 'contracts',
    DSTI = 'dsti',
    VALUATION = 'valuation',
    PROPERTY_MANAGER = 'property manager',
    MARKET_REPORTS = 'market reports',
    LOGIN_PAGE = 'login page',
    ONE_TAP = 'one tap',
    FIRM_REGISTRATION = 'firm registration',
}
