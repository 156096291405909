import { getCookie } from 'modules/cookie/getCookie';
import { clientTokenName } from 'modules/session/constants/clientTokenName';

export const getCookieToken = (cookies?: Record<string, string>): string => {
    if (cookies) {
        return cookies[clientTokenName];
    }

    return getCookie(clientTokenName);
};
