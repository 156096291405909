import {useEffect} from 'react';
import {useRecoilState} from 'recoil';
import {sessionApi} from 'modules/api/client';
import {appUserIsLoggedIn, isSessionLoadingState, sessionState} from 'modules/state/app/state';
import {SessionDtoToISessionMapper} from 'modules/session/helpers/SessionDtoToISessionMapper';
import { getCookieToken } from 'modules/session/helpers/getCookieToken';

const useFetch = (): void => {
    const [, setData] = useRecoilState(sessionState);
    const [, setIsLoading] = useRecoilState(isSessionLoadingState);
    const [, setIsUserLoggedIn] = useRecoilState(appUserIsLoggedIn);

    useEffect(() => {
        (async () => {
            if (!getCookieToken()) {
                setData(null);
                setIsLoading(false);
                setIsUserLoggedIn(false);
                return;
            }

            setIsLoading(true);
            try {
                const sessionResponse = await sessionApi.getSession();

                if (sessionResponse.status !== 200) {
                    throw `Response status was: ${sessionResponse.status}`;
                }

                if (!sessionResponse.data) {
                    throw 'Response data empty';
                }

                const session = SessionDtoToISessionMapper(sessionResponse.data);

                setData(session || undefined);
                setIsUserLoggedIn(!!session);
            } catch (error) {
                // TODO let system know
                setData(null);
            } finally {
                setIsLoading(false);
            }

        })();
    }, [setData, setIsLoading, setIsUserLoggedIn]);
};

export default useFetch;
