import { useRecoilState } from 'recoil';
import { gdprCookieBarState } from 'modules/state/app/state';
import { useCallback } from 'react';

interface IIsAllowed {
    googleTagManager: boolean;
    facebookPixel: boolean;
    gemius: boolean;
    rtbHouse: boolean;
    livemonitor: boolean;
    googleDoubleClick: boolean;
}

export interface IState {
    isAllowed: IIsAllowed;
}

interface IReturn {
    isAllowed: IIsAllowed;
    allow: (key: keyof IIsAllowed) => void;
}

const useCookieBar = (): IReturn => {
    const [ state, setState ] = useRecoilState(gdprCookieBarState);

    const allow = useCallback((key: keyof IIsAllowed) => {
        setState(state => ({
            ...state,
            isAllowed: {
                ...state.isAllowed,
                [key]: true
            }
        }));
    }, [setState]);

    return {
        isAllowed: state.isAllowed,
        allow
    };
};

export default useCookieBar;