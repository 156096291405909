import {FC} from 'react';
import Link from 'components/link/Link';
import logo from 'assets/image/brand/logo.png';
import {INDEX} from 'modules/route/routes';
import Image from 'components/image/Image';

export const BrandLogoLink: FC = () => {
    return <Link href={INDEX} sx={{display: 'flex'}}>
        <Image src={logo} alt="logo" width={210} height={30} placeholder="blur"/>
    </Link>;
};
