import {useState} from 'react';
import {Theme, useMediaQuery} from '@mui/material';
import Recaptcha from 'react-google-recaptcha';
import useTheme from 'modules/theme/state/useTheme';
import ETheme from 'modules/theme/state/ETheme';
import {AppConfigService} from 'modules/appConfig/AppConfigService';

interface IParameters {
    onChange?: (token: string | null) => void;
    hiddenInputName?: string;
}

interface IReturn {
    component: JSX.Element;
    hiddenInputComponent: JSX.Element | null;
}

const useRecaptcha = (parameters: IParameters): IReturn => {
    const {theme} = useTheme();
    const isSmDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const { onChange, hiddenInputName } = parameters;
    const [token, setToken] = useState<string>('');

    const resetToken = () => {
        setToken('');
        onChange('');
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (typeof window !== 'undefined' && window.IS_TESTING === true) {
        return {
            component: (
                <button data-test-id="recaptcha" type="button" onClick={() => onChange('test-token')}>
                    Dummy reCAPTCHA
                </button>
            ),
            hiddenInputComponent: hiddenInputName ? <input type="hidden" name={hiddenInputName} value="test-token" /> : null
        };
    }

    return {
        component: <Recaptcha
            theme={theme === ETheme.LIGHT ? 'light' : 'dark'}
            sitekey={AppConfigService.getGoogleRecaptchaSitekey()}
            hl="sk"
            size={isSmDown ? 'compact' : 'normal'}
            onChange={token => {
                const newToken = token || '';
                setToken(newToken);
                onChange(newToken);
            }}
            onExpired={resetToken}
            onErrored={resetToken}
        />,
        hiddenInputComponent: hiddenInputName ? <input type="hidden" name={hiddenInputName} value={token}/> : null
    };
};

export default useRecaptcha;
