import { FC } from 'react';
import {Text} from 'modules/theme/components/text/Text';
import {Drawer, Grid, Theme, useMediaQuery, Box, Palette} from '@mui/material';
import MenuItem from 'components/layout/header/components/navbar/common/mobile/menu/MenuItem';
import {AppConfigService} from 'modules/appConfig/AppConfigService';
import { REAL_ESTATE_LANDING_PAGE } from 'modules/route/routes';

interface IProps {
    isOpen: boolean;
    onClose: () => void;
}

const Menu: FC<IProps> = ({ isOpen, onClose }) => {
    const isLgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

    const nehnutelnostiPublicUrl = AppConfigService.getNehnutelnostiUrl();

    if (!isLgDown) return <></>;
    return <Drawer
        open={isOpen}
        anchor="left"
        onClose={onClose}
        sx={(theme: Theme) => ({
            '& .MuiDrawer-paper': {
                maxWidth: '90%',
                minWidth: 35,
                height: 'auto',
                maxHeight: '95vh',
                padding: theme.spacing(1, 0),
                [theme.breakpoints.up('sm')]: {
                    maxWidth: 240,
                    width: 240,
                },
                [theme.breakpoints.up('lg')]: {
                    maxWidth: '0%'
                }
            }
        })}>
        <Box sx={(theme: Theme) => (
            {
                marginTop: 6,
                [theme.breakpoints.up('md')]: {
                    marginTop: 8.125
                }
            })}>
            <Grid container>
                <Grid item xs={12} sx={(theme: Theme) => ({
                    '& > div:not(:last-child)': { borderBottom: `1px solid ${theme.palette.labelTertiary.main}` },
                    '& .MuiGrid-item:nth-child(1)': {
                        maxWidth: '100%',
                        flexBasis: '100%',
                        '& > a': {
                            padding: theme.spacing(1.5, 2),
                        },
                    },
                    ' & .MuiGrid-item:nth-child(2)': {
                        display: 'none'
                    }
                })}>
                    <MenuItem href={REAL_ESTATE_LANDING_PAGE} text="Realitné kancelárie" />
                    <MenuItem href={`${nehnutelnostiPublicUrl}/magazin-o-byvani`} text="Magazín" />

                    <Box px={2} py={1.3} bgcolor={(theme: Theme) => theme.palette.labelQuarternary.main}>
                        <Text variant="label2" bold color={(palette: Palette) => palette.labelSecondary}>
                            Užitočné info
                        </Text>
                    </Box>

                    <MenuItem href={`${nehnutelnostiPublicUrl}/developerske-projekty-a-novostavby/`} text="Developerské projekty a novostavby" />
                    <MenuItem href={`${nehnutelnostiPublicUrl}/ocenovanie-nehnutelnosti/`} text="Oceňovanie nehnuteľností" />
                    <MenuItem href={`${nehnutelnostiPublicUrl}/hypoteka`} text="Hypotéky" isSubmenu />
                    <MenuItem href={`${nehnutelnostiPublicUrl}/trhove-reporty`} text="Ceny realít" isSubmenu />
                    <MenuItem href={`${nehnutelnostiPublicUrl}/informacie`} text="Zmluvy" isSubmenu />
                    <MenuItem href={`${nehnutelnostiPublicUrl}/byvanie`} text="Katalóg bývania" isSubmenu />
                </Grid>
            </Grid>
        </Box>
    </Drawer>;
};

export default Menu;
