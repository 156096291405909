import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { analyticsFacebookPixePageViewQueueState, analyticsFacebookPixelEventQueueState, analyticsFacebookPixelTrackerState, analyticsGemiusPageViewQueueState, analyticsGemiusTrackerState, analyticsGtmEventQueueState, analyticsGtmTrackerState, analyticsLivemonitorEventQueueState, analyticsLivemonitorTrackerState, analyticsRtbHouseEventQueueState, analyticsRtbHouseTrackerState } from 'modules/state/app/state';

const useSender = (): void => {
    const [ gtmTracker ] = useRecoilState(analyticsGtmTrackerState);
    const [ gtmEventQueue, setGtmEventQueue ] = useRecoilState(analyticsGtmEventQueueState);
    const [ rtbHouseTracker ] = useRecoilState(analyticsRtbHouseTrackerState);
    const [ rtbHouseEventQueue, setRtbHouseEventQueue ] = useRecoilState(analyticsRtbHouseEventQueueState);
    const [ livemonitorTracker ] = useRecoilState(analyticsLivemonitorTrackerState);
    const [ livemonitorEventQueue, setLivemonitorEventQueue ] = useRecoilState(analyticsLivemonitorEventQueueState);
    const [ facebookPixelTracker ] = useRecoilState(analyticsFacebookPixelTrackerState);
    const [ facebookPixelEventQueue, setFacebookPixelEventQueue ] = useRecoilState(analyticsFacebookPixelEventQueueState);
    const [ facebookPixelPageViewQueue, setFacebookPixelPageViewQueue ] = useRecoilState(analyticsFacebookPixePageViewQueueState);
    const [ gemiusTracker ] = useRecoilState(analyticsGemiusTrackerState);
    const [ gemiusPageViewQueue, setGemiusPageViewQueue ] = useRecoilState(analyticsGemiusPageViewQueueState);

    useEffect(() => {
        if (!gtmTracker || !gtmEventQueue.length) {
            return;
        }

        for (const gtmEvent of gtmEventQueue) {
            gtmTracker.sendEvent(gtmEvent);
        }

        setGtmEventQueue([]);
    }, [gtmTracker, gtmEventQueue, setGtmEventQueue]);

    useEffect(() => {
        if (!rtbHouseTracker || !rtbHouseEventQueue.length) {
            return;
        }

        for (const rtbHouseEvent of rtbHouseEventQueue) {
            rtbHouseTracker.sendEvent(rtbHouseEvent);
        }

        setRtbHouseEventQueue([]);
    }, [rtbHouseTracker, rtbHouseEventQueue, setRtbHouseEventQueue]);

    useEffect(() => {
        if (!livemonitorTracker || !livemonitorEventQueue.length) {
            return;
        }

        for (const livemonitorEvent of livemonitorEventQueue) {
            livemonitorTracker.sendEvent(livemonitorEvent);
        }

        setLivemonitorEventQueue([]);
    }, [livemonitorTracker, livemonitorEventQueue, setLivemonitorEventQueue]);

    useEffect(() => {
        if (!facebookPixelTracker || !facebookPixelEventQueue.length) {
            return;
        }

        for (const facebookPixelEvent of facebookPixelEventQueue) {
            facebookPixelTracker.sendEvent(facebookPixelEvent);
        }

        setFacebookPixelEventQueue([]);
    }, [facebookPixelTracker, facebookPixelEventQueue, setFacebookPixelEventQueue]);

    useEffect(() => {
        if (!facebookPixelTracker || !facebookPixelPageViewQueue.length) {
            return;
        }

        for (let i = 0; i < facebookPixelPageViewQueue.length; i++) {
            facebookPixelTracker.pageView();
        }

        setFacebookPixelPageViewQueue([]);
    }, [facebookPixelTracker, facebookPixelPageViewQueue, setFacebookPixelPageViewQueue]);

    useEffect(() => {
        if (!gemiusTracker || !gemiusPageViewQueue.length) {
            return;
        }

        for (let i = 0; i < gemiusPageViewQueue.length; i++) {
            gemiusTracker.pageView(gemiusPageViewQueue[i]);
        }

        setGemiusPageViewQueue([]);
    }, [gemiusTracker, gemiusPageViewQueue, setGemiusPageViewQueue]);
};

export default useSender;
